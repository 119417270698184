<ig-inline-alert severity="warning">Oops... you found our secret debug panel! But don't be scared - you can't do anything evil here...</ig-inline-alert>
<br>
<div class="p-grid">
  <div class="p-col-6">
    <h5 class="headline">Panel Info</h5>
  <span class="ig-typo-panel-strong--medium labelsize1">Version: </span> <ig-copy-clipboard>{{ panelVersion }}</ig-copy-clipboard
  ><br />
  <span class="ig-typo-panel-strong--medium labelsize1">VersionHash: </span> <ig-copy-clipboard>{{ panelVersionHash }}</ig-copy-clipboard>
  <br />
  <span class="ig-typo-panel-strong--medium labelsize1">Branch: </span> <ig-copy-clipboard>{{ panelVersionBranch }}</ig-copy-clipboard>
  <br />
  <span class="ig-typo-panel-strong--medium labelsize1">Angular: </span> <ig-copy-clipboard>{{ angularVersion }}</ig-copy-clipboard>
  <br />
  <span class="ig-typo-panel-strong--medium labelsize1">gsclient-js: </span> <ig-copy-clipboard>{{ gsclientVersion }}</ig-copy-clipboard>
  <br />
  <span class="ig-typo-panel-strong--medium labelsize1">datacenter: </span> <ig-copy-clipboard>{{ datacenter }}</ig-copy-clipboard>
  </div>
  <div class="p-col-6">
    <h5 class="headline">User Info</h5>
  <span class="ig-typo-panel-strong--medium labelsize1">UserRole: </span> {{ userRole$ | async }} <br>
  <ng-container *ngIf="getCurrentUser$ | async as user" >
    <span class="ig-typo-panel-strong--medium labelsize1">Validated </span> {{ user.validated ? 'YES' : 'NO' }}<br>
    <span class="ig-typo-panel-strong--medium labelsize1">Locked </span> {{ user.locked ? 'YES' : 'NO' }}
  </ng-container>
  </div>
</div>
<div class="p-grid">
  <div class="p-col-12">
    <h5 class="headline">Session</h5>
    <ng-container *ngIf="getSessionData$ | async as session" >
      <span class="ig-typo-panel-strong--medium labelsize1">User-UUID: </span> <ig-copy-clipboard>{{ session.user_uuid }}</ig-copy-clipboard><br>
      <span class="ig-typo-panel-strong--medium labelsize1">Project-UUID: </span> <ig-copy-clipboard>{{ session.project_uuid }}</ig-copy-clipboard><br>
      <span class="ig-typo-panel-strong--medium labelsize1">Contract-UUID: </span> <ig-copy-clipboard>{{ session.contract_uuid }}</ig-copy-clipboard><br>
      <span class="ig-typo-panel-strong--medium labelsize1">Partner-UUID: </span> <ig-copy-clipboard>{{ session.partner_uuid }}</ig-copy-clipboard><br>
      <span class="ig-typo-panel-strong--medium labelsize1">Token: </span> <ig-copy-clipboard>{{ session.token }}</ig-copy-clipboard>
    </ng-container>
  </div>
</div>
  <br>
  <p-accordion>
    <p-accordionTab header="Active Features">
      <ul class="flist">
        <li *ngFor="let feature of activeFeatureFlags$ | async">
          {{ feature }}
          <span *ngIf="isInactivateFeatureByDependencies(feature)" class="ig-color-warning-red">
            &nbsp;(Inactive by depencies to other features!)
          </span>
        </li>
      </ul>
    </p-accordionTab>
    <p-accordionTab header="Account Ressource Limits">
        <div class="flist2" *ngIf="getCurrentContract$ | async as contract" >
          <div *ngFor="let item of contract.resource_limits | keyvalue">
            <span class="ig-typo-panel-strong--medium labelsize3">{{item.key}} </span> {{item.value}}
        </div>
      </div>
    </p-accordionTab>
    <p-accordionTab header="Account Current Limits(Usage)">
      <ng-container *ngIf="getLimits$ | async as limits" >
        <div *ngFor="let item of limits | keyvalue">
          <span class="ig-typo-panel-strong--medium labelsize3">{{item.key}} </span> {{item.value.current}} <span style="color: #999">(Max: {{item.value.max}})</span>
      </div>
      </ng-container>
    </p-accordionTab>
</p-accordion>

  <br>
<div >
  <h5 class="headline">User Store</h5>
  <div class="button-grid">

    <ig-button mode="secondary" (onClick)="resetProduct()" label="Reset ProductTeaser"></ig-button>
    <ig-button mode="secondary" (onClick)="resetHotspot()" label="Reset Hotspots"></ig-button>
    <ig-button mode="danger" (onClick)="resetUserStore()" label="Reset Complete User Store"></ig-button>
  </div>
</div>
