import { Injectable, Inject } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable, BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { HotkeysLegendComponent } from '../layout/hotkeys-legend/hotkeys-legend.component';
import { TranslateService } from '@ngx-translate/core';
import { OverlayAlertService } from '@gridscale/ingrid/components/overlay-alert';
import { DebugCenterComponent } from '../components/debug-center/debug-center.component';

type Options = {
  element: any;
  description: string | undefined;
  keys: string;
};

@Injectable({
  providedIn: 'root'
})
export class HotkeysService {
  hotkeys = new Map();
  defaults: Partial<Options> = {
    element: this.document
  };
  focusSearch = new BehaviorSubject(false);
  createAllowedOpen = new BehaviorSubject(false);
  escapeAllowedOpen = new BehaviorSubject(false);

  private isPaused = false;

  constructor(private eventManager: EventManager, @Inject(DOCUMENT) private document: Document, overlayAlertService: OverlayAlertService, translate: TranslateService) {
    /*this.addShortcut({ keys: 'shift.?' })
      .pipe()
      .subscribe(() => {
        // opens the central hotkey overview modal, located at the main-view

        overlayAlertService.custom(
          'Hotkeys',
          HotkeysLegendComponent,
          [
            {
              label: translate.instant('close'),
              mode: 'primary',
              keyShortcut: 'Escape',
              callback: (button, alert) => {
                alert.close();
              }
            }
          ],
          {},
          'fas fa-keyboard'
        );
      });*/

    const openDebugCenter = () => {
      overlayAlertService.custom(
        'DEBUG CENTER',
        DebugCenterComponent,
        [
          {
            label: 'Get me outta here!',
            mode: 'primary',
            keyShortcut: 'Escape',
            callback: (button, alert) => {
              alert.close();
            }
          }
        ],
        {},
        'fal fa-user-secret',
        'It\'s Magic!',
        '1000px'
      );
    }

    // mac
    this.addShortcut({ keys: 'control.alt.∂' }).subscribe((e: KeyboardEvent) => {
      openDebugCenter();
    });
    // windows
    this.addShortcut({ keys: 'control.alt.d' }).subscribe((e: KeyboardEvent) => {
      openDebugCenter();
      });
  }

  createNewSubscriber(_keys: string) {
    const newBehavior: BehaviorSubject<boolean | Event> = new BehaviorSubject(false);
    this.addShortcut({ keys: _keys }).subscribe((e: Event) => {
      newBehavior.next(e);
    });
    return newBehavior;
  }

  addShortcut(options: Partial<Options>): Observable<Event> {
    const merged = { ...this.defaults, ...options };
    const event = `keydown.${merged.keys}`;
    this.hotkeys.set(merged.keys, merged.description);
    return new Observable(observer => {
      const handler = (e: Event) => {
        if (!this.isPaused) {
          observer.next(e);
        }
      };
      const dispose = this.eventManager.addEventListener(merged.element, event, handler);
      return () => {
        dispose();
        this.hotkeys.delete(merged.keys);
      };
    });
  }

  /**
   * pause listening for hot keys (e.g. in vnc console)
   */
  pauseHotkeys() {
    this.isPaused = true;
  }
  /**
   * resume listening for hot keys
   */
  resumeHotkeys() {
    this.isPaused = false;
  }

  noop() {

  }
}
