import { Component, VERSION, inject } from '@angular/core';
import { FeatureToggleService } from './../../services/feature-toggle.service';
import { UserRoleService } from './../../services/userrole.service';
import { getCurrentContract } from './../../store/contract/contract.selectors';
import { getLimits } from './../../store/limits/limits.selectors';
import { getSessionData } from './../../store/session/session.selectors';
import { getCurrentUser } from './../../store/user/user.selectors';
import { UserStoreService } from './../../store/userstore/userstore.service';
import { OverlayAlertRef, OverlayAlertService } from '@gridscale/ingrid/components/overlay-alert';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';
import { CURRENT_DISRUPTOR_KEY } from '../disruptor/disruptor.component';
import { ButtonModule } from '../../../../../../ingrid/src/lib/elements/button/button.module';
import { AccordionModule } from 'primeng/accordion';
import { NgIf, NgFor, AsyncPipe, KeyValuePipe } from '@angular/common';
import { CopyClipboardModule } from '../../../../../../ingrid/src/lib/components/copy-clipboard/copy-clipboard.module';
import { InlineAlertModule } from '../../../../../../ingrid/src/lib/elements/inline-alert/inline-alert.module';
import { ApiService } from '../../services/api.service';

@Component({
    selector: 'app-debug-center',
    templateUrl: './debug-center.component.html',
    styleUrls: ['./debug-center.component.scss'],
    standalone: true,
    imports: [InlineAlertModule, CopyClipboardModule, NgIf, AccordionModule, NgFor, ButtonModule, AsyncPipe, KeyValuePipe]
})
export class DebugCenterComponent {
  panelVersion = _.get(window, ['gs_version']);
  panelVersionHash = _.get(window, ['gs_version_hash']);
  panelVersionBranch = _.get(window, ['gs_version_branch']);
  datacenter = '<!--#echo var="ssiCTRL_PLANE"-->';
  angularVersion = VERSION.full;
  gsclientVersion = inject(ApiService).getApiClient().VERSION;

  userRole$ = this.userroleService.userRole$.pipe(distinctUntilChanged());
  useruuid?: string;
  getCurrentUser$ = this.store.select(getCurrentUser)
  getCurrentContract$ = this.store.select(getCurrentContract)
  getSessionData$ = this.store.select(getSessionData)
  getLimits$ = this.store.select(getLimits)
  activeFeatureFlags$ = this.featureToggleService.allActiveFeatureFlags$.pipe(
    distinctUntilChanged((a, b) => _.isEqual(a, b)),
    tap(allFeatures => {
      this.disabledFeaturesDueDependencies = [];


      _.forEach(allFeatures, feature => {
        if (this.featureToggleService.isFeatureFlagDisabledThruDependency(feature)) {
          this.disabledFeaturesDueDependencies.push(feature);
        }
      })
    })
  );
  disabledFeaturesDueDependencies: string[] = [];

  constructor(
    private overlayAlertService: OverlayAlertService,
    private userStore: UserStoreService,
    private ref: OverlayAlertRef,
    private userroleService: UserRoleService,
    private featureToggleService: FeatureToggleService,
    private store: Store,
    private userStoreService: UserStoreService
  ) {
    this.getCurrentUser$.pipe(
      take(1),
      filter(user => user !== undefined)
    ).subscribe((_user) => { this.useruuid = _user!.object_uuid });
  }


  resetProduct() {
    this.userStoreService.set('disruptor_' + CURRENT_DISRUPTOR_KEY, '0');
    this.overlayAlertService.alert('Product Teaser Resetted', 'Done.');
  }


  resetHotspot() {

    this.userStoreService.set(['hotspot'], null);
    this.overlayAlertService.alert('Hotspots Resetted', 'Done.');
  }


  resetUserStore() {
    this.ref.close();

    this.overlayAlertService
      .confirm('Sure?', 'You will loose all your Panel Settings')
      .pipe(take(1))
      .subscribe(confirmed => {
        if (confirmed) {
          this.userStore
            .clear()
            .pipe(take(1))
            .subscribe(() => {
              this.overlayAlertService.alert('User store cleared', 'Done.');
            });
        }
      });
  }

  isInactivateFeatureByDependencies(feature: string) {
    return this.disabledFeaturesDueDependencies.indexOf(feature) >= 0;
  }
}
