import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { DisruptorContentComponent } from './disruptor-content/disruptor-content.component';
import { OverlayManagementService } from './../../services/overlay-management.service';
import { UserStoreService } from './../../store/userstore/userstore.service';
import * as _ from 'lodash';
import { getCurrentPartnerUuid } from './../../store/session/session.selectors';

export const CURRENT_DISRUPTOR_KEY = 'ovh'; // for store reset function in debug center

@Component({
  selector: 'app-disruptor',
  templateUrl: './disruptor.component.html',
  styleUrls: ['./disruptor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
  standalone: true
})
export class DisruptorComponent implements OnDestroy, AfterViewInit {
  /**
   * iso date string from when the disruptor should be visible
   */
  @Input() visibleFrom?: string;

  /**
   * iso date string until when the disruptor should be visible
   */
  @Input() visibleUntil?: string;


  @Input() key?: string;

  @Input() restrictToPartnerUuids?: string[];


  destroyed$: Subject<void> = new Subject();

  constructor(
    private readonly dialogService: DialogService,
    private readonly store: Store,
    private readonly userStore: UserStoreService,
    private overlayManagementService: OverlayManagementService,
  ) {

  }


  ngAfterViewInit(): void {
    if (_.get(window, ['Cypress']) !== undefined) {
      // disable disruptor for e2e tests
      return;
    }

    let visible = true;

    const now = Date.now();
    if (visible && this.visibleFrom) {
      const from = Date.parse(this.visibleFrom);

      if (from > now) {
        visible = false;
      }
    }

    if (visible && this.visibleUntil) {
      const until = Date.parse(this.visibleUntil);

      if (until < now) {
        visible = false;
      }
    }


    if (visible) {


      combineLatest([
        this.userStore.loaded$.pipe(filter(loaded => !!loaded)),
        this.store.select(getCurrentPartnerUuid)
      ]).pipe(
        takeUntil(this.destroyed$),
        take(1),
        filter(([userStoreLoaded, partnerUuid]) => !!userStoreLoaded && !!partnerUuid),
        map(([userStoreLoaded, partnerUuid]) => {

          if (this.restrictToPartnerUuids?.length) {
            if (!(partnerUuid && this.restrictToPartnerUuids.indexOf(partnerUuid) >= 0)) {
              return false; // other partner
            }
          }


          if (this.key !== undefined && this.userStore.get('disruptor_' + this.key) === '1') {
            return false; // already seen
          }

          return true;
        })
      ).subscribe(showDialog => {
        if (showDialog) {
          this.overlayManagementService.reportStatus('show_disruptor', () => {
            this.showDialog();
          });
        } else {
          this.overlayManagementService.reportStatus('show_disruptor', false);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private showDialog() {
    const dialog = this.dialogService.open(DisruptorContentComponent, {
      width: '800px',
      dismissableMask: false,
      closeOnEscape: true,
      modal: true,
      showHeader: true,
      closable: true,
      styleClass: 'disruptor-dialog',
      contentStyle: {
        padding: '0',
      },
      data: {
        key: this.key
      }
    });

    dialog.onClose.pipe(
      takeUntil(this.destroyed$),
      take(1)
    ).subscribe(() => {
      //save to user store
      if (this.key) {
        this.userStore.set('disruptor_' + this.key, '1');
      }
    });
  }


}
